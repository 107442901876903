import React from "react";
import { motion } from "framer-motion";
import "../styles/Cta.css";

const Cta = () => {
  return (
    <motion.div
      className="relative isolate min-h-screen flex items-center justify-center bg-gray-100 dark:bg-black-200"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div
        className="absolute inset-x-0 top-1/4 -z-10 transform-gpu overflow-hidden blur-2xl animate-move-bubble1"
        aria-hidden="true"
      >
        <div className="relative left-1/2 h-80 w-80 -translate-x-1/2 bg-gradient-to-tr from-[#C47124] to-[#F79D65] opacity-5 rounded-full" />
      </div>
      <div
        className="absolute inset-x-0 bottom-1/4 -z-10 transform-gpu overflow-hidden blur-2xl animate-move-bubble2"
        aria-hidden="true"
      >
        <div className="relative left-1/4 h-40 w-40 -translate-x-1/2 bg-gradient-to-br from-[#C47124] to-[#F79D65] opacity-20 rounded-full" />
      </div>
      <div
        className="absolute inset-x-0 top-1/2 right-1/4 -z-10 transform-gpu overflow-hidden blur-2xl animate-move-bubble3"
        aria-hidden="true"
      >
        <div className="relative left-1/2 h-60 w-60 -translate-x-1/2 bg-gradient-to-tl from-[#C47124] to-[#F79D65] opacity-10 rounded-full" />
      </div>
      <div className="mx-auto px-3 max-w-2xl text-center">
        <motion.h2
          className="text-base font-semibold leading-7 text-primary"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          Hi there, I'm
        </motion.h2>

        <motion.p
          className="text-5xl sm:text-6xl font-extrabold tracking-tight text-gray-900 dark:text-gray-100"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.6 }}
        >
          David Mugisha
        </motion.p>

        <motion.p
          className="mt-6 text-lg leading-8 dark:text-[#BEC1DD] text-gray-700"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.6 }}
        >
          "I am a passionate software engineer driven by a relentless pursuit of
          innovation and excellence in crafting impactful solutions through
          code."
        </motion.p>
        <motion.div
          className="py-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.6 }}
        >
          <a
            href="mailto:davidkhalifa47@gmail.com"
            className="bg-primary text-white py-3 px-4 rounded"
            aria-label="Email David Mugisha"
          >
            Reach out!
          </a>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Cta;
