import React, { useEffect } from "react";
import "../styles/About.css";

const techStack = [
  { name: "React Js", image: "/re.svg" },
  { name: "Next Js", image: "/n.svg" },
  { name: "Ionic", image: "/ionic.svg" },
  { name: "Typescript", image: "/type.svg" },
  { name: "Javascript", image: "/java.svg" },
  { name: "Tailwind Css", image: "/tail.svg" },
  { name: "Graphql", image: "/graph.svg" },
  // { name: "Node Js", image: "/node.svg" },
  { name: "Framer Motion", image: "/framer-motion.svg" },
];

const About = () => {
  useEffect(() => {
    const headingSpan = document.querySelector(".hidden-text h1 span");
    const paragraphSpan = document.querySelector(".hidden-text p span");

    const handleScroll = () => {
      const windowHeight = window.innerHeight;

      if (headingSpan) {
        const headingPosition = headingSpan.getBoundingClientRect().top;
        const headingReveal = Math.min((windowHeight - headingPosition) / (windowHeight * 0.1), 1);
        headingSpan.style.backgroundSize = `${headingReveal * 100}% 100%`;
      }

      if (paragraphSpan) {
        const paragraphPosition = paragraphSpan.getBoundingClientRect().top;
        const paragraphReveal = Math.min((windowHeight - paragraphPosition) / (windowHeight * 0.65), 1);
        paragraphSpan.style.backgroundSize = `${paragraphReveal * 100}% 100%`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-white dark:bg-secondary" id="about">
      <div className="p-4 md:p-16 rounded-lg">
        <div className="hidden-text">
          <h1 className="section-title">
            <span className="text-black dark:text-white">About Me</span>
          </h1>
          <p>
            <span className="text-black dark:text-white">
              I'm a passionate developer dedicated to creating innovative
              solutions. My journey in coding began with a curiosity for
              technology, evolving into a commitment to delivering user-friendly
              interfaces and robust applications. I believe in writing clean,
              maintainable code and thrive in collaborative, innovative
              environments. Let's connect and discuss how we can bring your ideas
              to life!
            </span>
          </p>
        </div>
        <div className="text-black dark:text-white skills">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8 pb-8 md:pb-28">
            {techStack.map((tech, index) => (
              <div key={index} className="flex items-center">
                <img
                  src={tech.image}
                  alt={tech.name}
                  className="w-10 h-10 md:w-12 md:h-12 mr-4"
                />
                <span>{tech.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
