import { FaLocationArrow } from "react-icons/fa6";
import { socialMedia } from "../data";
import MagicButton from "./MagicButton";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="w-full pt-28 pb-12 bg-gray-100 dark:bg-black-200">
      {/* background grid */}
      <div className="w-full absolute left-0 -bottom-72 min-h-96">
        <img
          src="/footer-grid.svg"
          alt="grid"
          className="w-full h-100 opacity-30"
        />
      </div>

      <div className="flex flex-col items-center">
        <h1 className="heading lg:max-w-[45vw] text-center">Reach Out!</h1>
        <p className="dark:text-[#BEC1DD] text-gray-700 mt-10 md:mt-10 my-5 text-center px-4">
          I’m incredibly excited to collaborate with you on your projects!
          Please reach out today, and <br />
          let’s explore together how I can bring your unique vision to life in
          the best possible way.
        </p>
        <a href="mailto:davidkhalifa47@gmail.com">
          <MagicButton
            title="Let's get in touch"
            icon={<FaLocationArrow />}
            position="right"
          />
        </a>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center px-5 md:px-20 mt-20 md:mt-36">
        <p className="md:text-base text-sm md:font-normal font-light text-center">
          Copyright © {currentYear} David Mugisha
        </p>

        <div className="flex items-center md:gap-3 gap-6 mt-4 md:mt-0">
          {socialMedia.map((info) => (
            <a
              key={info.id}
              href={info.href}
              target="_blank"
              rel="noopener noreferrer"
              className="w-10 h-10 cursor-pointer flex justify-center items-center backdrop-filter backdrop-blur-lg saturate-180 bg-opacity-75 bg-black-200 rounded-lg border border-black-300"
            >
              <img src={info.img} alt={info.name} width={20} height={20} />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
