import React from "react";

const Experience = () => {
  const experiences = [
    {
      company: "Fatherhood Tech",
      role: "Front-End Engineer",
      employmentType: "Full-time",
      date: "April 2023 - Present",
      responsibilities: [
        "Collaborated with the design team to translate UI/UX wireframes into functional front-end components, ensuring accurate implementation of design specifications.",
        "Developed and implemented responsive design principles to provide a consistent experience across various devices.",
        "Actively collaborated with cross-functional teams, incorporating feedback to ensure the seamless integration of front-end components within projects.",
      ],
    },
    {
      company: "Majestic Creative Agency",
      role: "Software Engineer",
      employmentType: "Remote",
      date: "September 2024 - Present",
      responsibilities: [
        "Lead a small team of developers in designing and implementing innovative solutions that address business needs and enhance operational efficiency.",
        "Spearheaded the development of the front end of the agency's website, ensuring a user-centric design that aligns with branding and user experience standards.",
        "Participated in code reviews, providing constructive feedback to enhance code quality and promote team collaboration.",
      ],
    },
    {
      company: "BST",
      role: "Peer Tutor",
      employmentType: "Part-time",
      date: "February 2024 - Present",
      responsibilities: [
        "Assist students in understanding complex concepts in software development and coding practices through one-on-one tutoring sessions.",
        "Help students overcome challenges by advising them on coding issues and providing guidance on problem-solving techniques.",
      ],
    },
  ];

  return (
    <div
      className="py-20 lg:px-5 text-black dark:text-white dark:bg-black-200 bg-gray-100"
      id="experience"
    >
      <div className="m">
        <h2 className="text-4xl font-bold mb-16 text-center">Experience</h2>
        {experiences.map((experience, index) => (
          <div key={index} className="mb-12 rounded-lg skills">
            <div className="flex items-center mb-4">
              <a
                href="/"
                className="text-white inline-block mr-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  className="dark:text-gray-500 text-black"
                  fill="currentColor"
                >
                  <path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z" />
                </svg>
              </a>
              <a href="/">
                <h3 className="text-2xl font-semibold">{experience.company}</h3>
              </a>
            </div>
            <h4 className="text-xl font-semibold">
              {experience.role}{" "}
              <span className="text-sm font-normal dark:text-gray-500 text-gray-900">
                • {experience.employmentType}
              </span>
            </h4>
            <p className="dark:text-gray-500 text-gray-900 mb-4">
              {experience.date}
            </p>
            <ul className="list-disc pl-5 space-y-2 dark:text-[#BEC1DD] text-gray-900">
              {experience.responsibilities.map((responsibility, i) => (
                <li key={i}>{responsibility}</li>
              ))}
            </ul>
            <hr className="mt-7 dark:text-gray-500 text-gray-900" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Experience;
