import React from "react";
import '../styles/animation.css';

const AnimatedHero = () => {
  return (
    <div>
      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};

export default AnimatedHero;
