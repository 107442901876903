import React from "react";
import NavBar from "../components/NavBar";
import AnimatedHero from "../components/AnimatedHero";
import Cta from "../components/Cta";
import About from "../components/About";
import Experience from "../components/Experience";
import Work from "../components/Work";
import Footer from "../components/Footer";
import RecentProjects from "../components/Portfolio";
import "../styles/Home.css";

const Home = () => {
  return (
    <div id="home">
      <NavBar />
      <AnimatedHero />
      <Cta />
      <About />
      <Experience />
      {/* <Work /> */}
      <RecentProjects />
      <Footer />
    </div>
  );
};

export default Home;
