import { useState, useEffect } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  SunIcon,
  MoonIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import "../styles/NavBar.css";

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(
    () => window.matchMedia("(prefers-color-scheme: dark)").matches
  );
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState("home");

  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
  }, [darkMode]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);

      const sections = ["home", "about", "experience", "projects"];
      const scrollPosition = window.scrollY + window.innerHeight / 3;

      sections.forEach((section) => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          const { top, height } = sectionElement.getBoundingClientRect();
          const sectionTop = window.scrollY + top;
          const sectionHeight = height;

          if (
            scrollPosition >= sectionTop &&
            scrollPosition < sectionTop + sectionHeight
          ) {
            setActiveLink(section);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <motion.div
        initial={{ y: -100, opacity: 10 }}
        animate={{ y: 0, opacity: 10 }}
        transition={{ duration: 0.4, ease: "easeIn" }}
      >
        <header
          className={`navbar-container fixed inset-x-0 top-0 z-50 transition-shadow ${
            isScrolled
              ? "shadow-md backdrop-blur-lg bg-opacity-30 border-b dark:border-gray-900 border-gray-200"
              : ""
          }`}
          style={{ margin: 0, padding: 0 }}
        >
          <nav
            className="flex items-center justify-between px-3 lg:mt-0 mt-3 lg:px-20"
            aria-label="Global"
          >
            <div className="relative flex lg:flex-1">
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className="-m-1.5 p-1.5"
              >
                <img
                  className="h-24 lg:h-28 w-auto"
                  src="/images/david2.png"
                  alt="Your Company Logo"
                />
              </Link>
            </div>
            <div className="flex lg:hidden items-center gap-4">
              <button
                onClick={() => setDarkMode((prevMode) => !prevMode)}
                className="text-gray-900 dark:text-gray-100 focus:outline-none"
              >
                {darkMode ? (
                  <SunIcon className="h-6 w-6" />
                ) : (
                  <MoonIcon className="h-6 w-6" />
                )}
              </button>
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            {/* Desktop Navigation Links */}
            <div className="hidden lg:flex lg:gap-x-12">
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className={`text-sm font-semibold leading-6 pointer ${
                  activeLink === "home"
                    ? "text-primary"
                    : "text-gray-900 dark:text-gray-100"
                }`}
              >
                &lt;Home /&gt;
              </Link>
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className={`text-sm font-semibold leading-6 pointer ${
                  activeLink === "about"
                    ? "text-primary"
                    : "text-gray-900 dark:text-gray-100"
                }`}
              >
                &lt;About Me /&gt;
              </Link>
              <Link
                to="experience"
                spy={true}
                smooth={true}
                offset={-40}
                duration={500}
                className={`text-sm font-semibold leading-6 pointer ${
                  activeLink === "experience"
                    ? "text-primary"
                    : "text-gray-900 dark:text-gray-100"
                }`}
              >
                &lt;Experience /&gt;
              </Link>
              <Link
                to="projects"
                spy={true}
                smooth={true}
                offset={-40}
                duration={500}
                className={`text-sm font-semibold leading-6 pointer ${
                  activeLink === "projects"
                    ? "text-primary"
                    : "text-gray-900 dark:text-gray-100"
                }`}
              >
                &lt;Projects /&gt;
              </Link>
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center gap-6">
              <button
                onClick={() => setDarkMode((prevMode) => !prevMode)}
                className="text-gray-900 dark:text-gray-100 focus:outline-none"
              >
                {darkMode ? (
                  <SunIcon className="h-6 w-6" />
                ) : (
                  <MoonIcon className="h-6 w-6" />
                )}
              </button>
              <a
                href="/resume.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-primary text-white py-2 px-4 rounded"
              >
                Resume
              </a>
            </div>
          </nav>
          {/* Mobile Menu Dialog */}
          <Dialog
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)}
          >
            <div className="fixed inset-0 z-50" />
            <DialogPanel className="fixed top-16 right-4 z-50 w-60 rounded-lg bg-primary p-5 shadow-lg sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between mb-4">
                <a href="/" className="-m-1.5 p-1.5">
                  <img
                    className="h-12 w-auto"
                    src="/images/david2.png"
                    alt="mlogo"
                  />
                </a>
                <button
                  type="button"
                  className="rounded-md p-2 text-white hover:bg-opacity-20"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flow-root">
                <div className="-my-4 divide-y divide-gray-200/20">
                  <div className="space-y-2 py-4">
                    <Link
                      to="home"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                      onClick={() => setMobileMenuOpen(false)}
                      className={`block rounded-md px-4 py-2 text-base font-medium text-white hover:bg-white hover:bg-opacity-20 ${
                        activeLink === "home" ? "bg-opacity-20 bg-white" : ""
                      }`}
                    >
                      &lt;Home /&gt;
                    </Link>
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-40}
                      duration={500}
                      onClick={() => setMobileMenuOpen(false)}
                      className={`block rounded-md px-4 py-2 text-base font-medium text-white hover:bg-white hover:bg-opacity-20 ${
                        activeLink === "about" ? "bg-opacity-20 bg-white" : ""
                      }`}
                    >
                      &lt;About Me /&gt;
                    </Link>
                    <Link
                      to="experience"
                      spy={true}
                      smooth={true}
                      offset={-50}
                      duration={500}
                      onClick={() => setMobileMenuOpen(false)}
                      className={`block rounded-md px-4 py-2 text-base font-medium text-white hover:bg-white hover:bg-opacity-20 ${
                        activeLink === "experience"
                          ? "bg-opacity-20 bg-white"
                          : ""
                      }`}
                    >
                      &lt;Experience /&gt;
                    </Link>
                    <Link
                      to="projects"
                      spy={true}
                      smooth={true}
                      offset={-50}
                      duration={500}
                      onClick={() => setMobileMenuOpen(false)}
                      className={`block rounded-md px-4 py-2 text-base font-medium text-white hover:bg-white hover:bg-opacity-20 ${
                        activeLink === "projects"
                          ? "bg-opacity-20 bg-white"
                          : ""
                      }`}
                    >
                      &lt;Projects /&gt;
                    </Link>
                  </div>
                  <div className="pt-4">
                    <a
                      href="/resume.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-full text-center rounded-md bg-white text-primary py-2 font-semibold hover:bg-opacity-90"
                    >
                      Resume
                    </a>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </Dialog>
        </header>
      </motion.div>
    </div>
  );
};

export default NavBar;
